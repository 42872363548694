* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.loader {
  background-color: white;
  display: grid;
  place-items: center;
  min-height: 100vh;
}
.main_loading {
    width: 150px;
}
.loader2 {
  position: relative;
}

.dot {
  position: absolute;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #2c2f73;
  animation: bounce 1.3s linear infinite;
}

.dot:nth-child(1) {
  left: -1.5rem;
}

.dot:nth-child(2) {
  animation-delay: -1s;
}

.dot:nth-child(3) {
  right: -2.5rem;
  animation-delay: -0.8s;
}

@keyframes bounce {
  0%,
  66%,
  100% {
      transform: initial;
  }

  33% {
      transform: translatey(-1rem);
  }
}


body {
  font-family: "Inter" !important;
  background-color: #F2F2F2;
}

.txt-center {
  text-align: center !important;
}

.txt-right {
  text-align: right !important;
}

.txt-left {
  text-align: left !important;
}

/* scrollbar start*/

::-webkit-scrollbar {
  width: 0px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(166, 166, 166);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2C2F73;
  border-radius: 10px;
}

/* scrollbar End*/


/* ant-input start */


.ant-input {
  font-family: "Inter" !important;
  font-size: 13px;
  height: 38px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-input:focus,
.ant-input-focused {
  border-color: none !important;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-input-affix-wrapper {
  font-family: "Inter" !important;
  font-size: 13px;
  height: 38px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: none !important;
  border: none;
}

.ant-input-affix-wrapper .ant-input {
  box-shadow: none;
  border: none;
  height: auto;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  background-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #ff4d4f 0px 0px 0px 1px;
  font-family: "Inter" !important;
  font-size: 13px !important;
  border: none;
  margin: 0 !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #ff4d4f 0px 0px 0px 1px;
  font-family: "Inter" !important;
  font-size: 13px !important;
  border: none;
  margin: 0 !important;
}

.ant-input:hover {
  border-color: none !important;
}

.ant-input-affix-wrapper:hover {
  border-color: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  position: absolute;
  right: -15px;
  top: 1px;
}

.ant-form-item {
  margin: 0 0 14px;
}

.ant-form-item-label>label {
  font-family: "Inter" !important;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

/* ant-input end */


/* pagination start */

.ant-pagination-item a {
  border-color: #D9D9D9 !important;
  background: #f2f2f2;
  font-weight: 500;
}

.ant-pagination-item a:hover {
  border: none;
  background: #f2f2f2;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-color: #D9D9D9 !important;
  background-color: transparent;
  color: #D9D9D9;
}

.ant-pagination-item-active {
  background: #2C2F73 !important;
  border-color: #2C2F73;
}

.ant-pagination-item-active a {
  color: white;
  background: #2C2F73 !important;
  border-color: #2C2F73;
}

.ant-pagination-item-active a:hover {
  color: white;
  border-color: #2C2F73;
}

.ant-pagination-item-active:hover {
  background-color: #2C2F73 !;
  border-color: #2C2F73;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

/* pagination end */

/* ant-modal start */

.ant-modal-header,
.ant-modal-footer,
.ant-modal-close-x {
  display: none;
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  border-radius: 10px;
}

.ant-modal-body {
  padding: 25px !important;
  border-radius: 10px;
}


.ant-modal-content {
  border-radius: 10px !important;
}

/* ant-modal end */


/* thales table start */
.thales-table {
  margin-top: 25px;
  max-width: 100%;
  max-height: 65vh;
  min-height: 65vh;
  overflow-x: auto;
}

.thales-table table {
  width: 100%;
  font-family: 'Roboto';
  border-collapse: collapse;
}

.thales-table::-webkit-scrollbar {
  height: 6px;
  display: inline !important;
}

.thales-table table thead {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 99;
}


.thales-table table tr {
  white-space: nowrap;
}


.thales-table table thead tr th {
  padding: 15px;
  color: #000000D9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 20px;
}

.thales-table table thead tr th:before {
  position: absolute;
  top: 50%;
  margin-left: -17px;
  width: 1.5px;
  height: 1.4em;
  background-color: #00000015;
  transform: translateY(-50%);
  content: "";
}

.thales-table table thead tr th:first-child:before {
  width: 0px;
  height: 0em;
}

.thales-table table thead tr th .anticon[tabindex] {
  cursor: pointer;
  padding-left: 10px;
  color: #2C2F73;
}

.thales-table table thead tr {
  font-size: 12px;
  padding-bottom: 10px;
  text-align: left;
  white-space: nowrap;
}

.thales-table table tbody tr td {
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}

.thales-table table tbody tr {
  border-bottom: 1px #0000000F solid;
}

.thales-table .action-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.thales-table .header-pointer {
  cursor: pointer;
}

.thales-table .no-data {
  height: 52vh;
}

.thales-table .visitor-no-data {
  height: 50vh;
}

.thales-table .thales-table-name{
  max-width: 200px;
  cursor: pointer;
}

 .ant-tooltip {
  min-width: auto !important;
  max-width: 30% !important;
}

 .ant-tooltip-inner {
  color: black !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
}

 .ant-tooltip-placement-top {
  padding-bottom: 0px !important;
}

.thales-table .action-btns span {
  color: #2C2F73;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.thales-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.thales-pagination p {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0em;
  padding: 0px;
  margin: 0px;
}

/* thales table end */

/* antd-Data-Picker Start */

.ant-picker {
  width: 100%;
  border: none;
  height: 35px;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-picker-input>input::placeholder {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.005em;
  text-align: left;
}

.ant-picker-focused,
.ant-picker:hover {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.ant-picker-input>input {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.005em;
  text-align: left;
}

.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border: none;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #ff4d4f 0px 0px 0px 1px;
}

.ant-picker-content td,
.ant-picker-content th {
  border: none;
  text-align: center;
}

/* antd-Data-Picker end */


.popup-modal {
  margin-top: -50px;
}

.popup-modal .popup-title {
  margin-bottom: 10px;
  border-bottom: 1px solid #E2E5FF;
}


.popup-modal h2 {
  font-family: "Inter";
  font-size: 23px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
}

.popup-modal p {
  font-family: "Inter";
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #8F92AB;
  margin: 0px;
  padding: 0px;
}

.popup-modal .ant-form-item-label>label {
  font-family: "Inter" !important;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.popup-modal .ant-select {
  width: 100% !important;
}

.popup-modal .repository-img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 10px;
}

.popup-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.popup-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  font-family: "Inter" !important;
  font-size: 13px;
  height: 38px;
  padding: 5px 10px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.popup-modal .ant-select-selector:focus-within {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
}

.popup-modal .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 13px;
}

.popup-modal .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
  margin-right: 13px;
  padding: 5px 0px;
  border-right: 1.5px solid rgba(128, 128, 128, 0.395);
}

.popup-modal .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 35px;
}

.popup-modal.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.popup-modal .ant-select-selection-placeholder {
  font-family: "Inter" !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  text-align: left;
  color: rgba(128, 128, 128, 0.573) !important;
}

.popup-modal .input-or-drop {
  position: absolute;
  left: 120px;
  z-index: 99;
  background-color: rgb(122, 182, 122);
}


.popup-modal .ant-form input[type=file],
.popup-modal .ant-form input[type=file]:focus {
  font-family: "Inter" !important;
  font-size: 13px;
  height: 38px;
  padding: 6px 8px;
  border: none !important;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.popup-modal .ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 38px !important;
  background-color: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.popup-modal .ant-input-group-wrapper .ant-input-group-addon:last-child {
  width: 100px;
  padding: 0px;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.popup-modal .ant-input-group-wrapper .ant-input-group-addon:last-child .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 16px;
}


.popup-modal .ant-input-group-wrapper .ant-input-group-addon:last-child .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
  margin-right: 13px;
  border-right: none;
}

.popup-modal .ant-input-group-wrapper .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  margin: 0px;
  border: none;
  box-shadow: none !important;
  border-radius: 0px;
  color: black !important;
}

.popup-modal .popup-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
}

/* .active {
  color: red !important;
} */

.ant-form-item-explain {
  font-family: 'Inter' !important;
  font-size: 12px;
  font-weight: 500;
}


.ant-empty {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ant-popover-inner-content {
  padding: 30px;
}

.ant-popover {
  min-width: 20vw;
}

.ant-popover-message {
  padding: 15px
}

.ant-popover-buttons {
  padding-bottom: 15px;
  padding-right: 15px;
}

.ant-btn-primary {
  border-color: #2C2F73;
  background-color: #2C2F73;
}

.loading {
  width: 150px;
  position: relative;
  left: 45%;
  right: 45%;
  /* mix-blend-mode: color-burn; */
}

.textarea.ant-input {
  background-color: transparent;
}

.ant-input {
  background-color: transparent !important;
}

.anticon-question-circle {
  color: red;
}

.ant-btn:hover {
  background-color: #2c2f73;
  color: white;
}

.astreik-symbol {
  margin-right: 4px;
  margin-left: 2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  position: relative;
  top: -3px;
}

.ant-form-item-explain-error {
  position: absolute !important;
}

.session-warning {
  background-color: #ead865;
  border-radius: 10px;
  position: relative;
  top: -10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.session-warning p {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  padding: 0px !important;
}

.session-warning img {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px !important;
}

span.ant-radio+* {
  font-family: "Source Sans Pro" !important;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    color: #09101D;
}