.profile-head {
    margin-top: 30px;
}

h1 {
    font-size: 20px;
    font-weight: 600;
}

.profile-header {
    display: flex;
    position: sticky !important;
    top: 0px !important;
}

.profile-header a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C4652;
    padding: 8px 15px;
    border: 1px solid #D3D3E1;
    white-space: nowrap;
}

.profile-header a:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.profile-header a:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.profile-header .active {
    background-color: #2C2F73;
    border: 1px solid #2C2F73;
    color: white;
    font-weight: 600;
}

.profile-header .active img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(106%) contrast(101%);
}

.profile-header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.profile-header-actions .ant-btn {
    margin-left: 10px;
    background-color: #5EBFD4;
    color: white;
    border-radius: 12px;
    padding: 10px 16px;
    height: initial;
    font-size: 16;
    font-weight: 500;
    display: flex;
}

.profile-header-actions .ant-btn img {
    margin-left: 5px;
}

.profile-header-actions .ant-input-affix-wrapper {
    background-color: transparent;
    height: 40px;
}

.profile-header-actions .ant-input {
    background-color: transparent;
}

.profile-header-actions .ant-input::placeholder {
    color: #575B80;
}

.thales-form .switch-container {
    position: relative;
    top: 20px;
    text-align: left;
}

.thales-form .edit-switch-container {
    margin-top: 5px;
    text-align: left;
}

.thales-form .switch-span {
    font-family: "Source Sans Pro" !important;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    color: #09101D;
}

.thales-form .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.thales-form .upload-btn-img {
    width: 20px;
    height: 20px;
}

.thales-form .ant-upload {
    font-family: "Inter" !important;
    font-size: 13px;
    color: #8F92AB;
    font-weight: 500;
}

.thales-form .ant-upload.ant-upload-select {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 38px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 6px;
    padding: 12px 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thales-form .profile-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.role-form .switch-container .switch-span {
    font-family: "Inter" !important;
    font-size: 15px;
        font-weight: 700;
    color: #09101D;
    margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .role-form .switch-container .status-span {
        font-family: "Inter" !important;
        font-size: 14px;
        font-weight: 600;
        color: #09101D;
        margin-left: 5px;
    }
    
    .role-form .switch-container .title-content {
        padding-top: 30px;
}

.role-form .switch-container tr td {
    padding: 16px 16px 16px 0px;
        border-bottom: 1px solid #d9d9d9;
        font-family: "Source Sans Pro" !important;
        font-size: 14px;
        font-weight: 600;
        color: #09101D;
        margin-left: 10px;
}

.role-form .switch-container .switch-container {
    margin-top: 15px;
}

.role-form .ant-switch-checked {
    background-color: #2C6ECB;
}

.role-form .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2C6ECB;
    border-radius: 4px;
}

.role-form .ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #2C6ECB;
    border-radius: 4px;
}

.role-form .ant-checkbox+span {
    font-family: "Source Sans Pro" !important;
    font-size: 14px;
    font-weight: 600;
    color: #09101D;
}
.profile-form .profile-title {
    margin-bottom: 10px;
    border-bottom: 1px solid #E2E5FF;
}


.profile-form h2 {
    font-family: "Inter";
    font-size: 23px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.01em;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-bottom: 2px;
}

.profile-form p {
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #8F92AB;
}

.profile-form .ant-input {
    background-color: transparent;
}

.profile-form .ant-input[disabled] {
    font-family: "Inter" !important;
    font-size: 13px;
        height: 38px;
        border: none;
        border-radius: 6px;
        font-weight: 500;
        background-color: rgba(128, 128, 128, 0.099) !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

.profile-form .popup-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
}

.thales-form .popup-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
}

.ant-input-affix-wrapper {
    background-color: transparent;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: transparent !important;
}