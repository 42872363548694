.thales-app-view .thales-sidebar {
    background-color: #f2f2f2;
    height: 90vh;
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
    border-right: 1px solid #D1D5DB;
    position: sticky;
    top: 0;
}

.sidebar-logo {
    width: 130px !important;
}

.thales-app-view .thales-body {
    width: 100%;
    max-height: 100vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    overflow-y: auto;
    padding: 0px 40px;
}

.sidebar-main .sidebar-links {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 15px;
}

.sidebar-main .sidebar-links a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Inter" !important;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    padding-left: 12px;
    margin-right: 20px;
    border-left: 4px solid transparent;
    color: #6A6A6A;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.sidebar-main .sidebar-links .active {
    color: #2C2F73;
    font-weight: 600 !important;
}

.sidebar-main .sidebar-links .active:before {
    position: absolute;
    left: 0;
    content: "";
    background-color: #5EBFD4;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 4px;
    height: 40px;
}

.sidebar-main .sidebar-links .active img {
    filter: invert(8%) sepia(63%) saturate(5840%) hue-rotate(232deg) brightness(50%) contrast(119%);
}

.download-app {
    position: absolute; 
    bottom: 45px !important;      
    left: 0;           
    right: 0;        
    padding: 1rem 1.5rem; 
    transition: all 0.2s ease-in-out;
  }

  .download-app p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #6A6A6A;
    text-align: center;
  }
  
  .download-app a {
    display: block;
    width: 100% !important;
    padding: 3px; 
    border: none;         
    border-radius: 5px;
    color: #333;      
    text-decoration: none;
    text-align: center;
    font-weight: normal; 
    text-transform: none;  
    background-color: #e0e0e0; 
    transition: all 0.2s ease-in-out; 
    cursor: pointer;     
  }
  
  .download-app a:hover {
    background-color: #d0d0d0; 
  }

.profile {
    position: absolute;
    bottom: 0px !important;
    left: 0px;
    right: 0px;
    padding: 0px 15px;
}

.profile p {
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #7C7C7C;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px !important;
}

.profile p b {
    font-size: 13px;
    margin-bottom: 0px;
    color: #000;
    font-weight: 600;
}

.profile .dote {
    margin-top: 8px;
}

.profile .sidebar-pic {
    height: 35px;
    width: 35px;
    border-radius: 50px;
}

.pop-hover-content {
    width: 100%;
    cursor: pointer;
}

.pop-hover-content .pop-profile {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    padding: 16px;
}

.pop-hover-content p {
    font-family: "Inter" !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.pop-hover-content .pop-logout {
    padding: 16px;
}

.pop-hover-content hr {
    border: 1px solid #E0E2F8;
}


/* .ant-popover-start*/

.ant-popover {
    min-width: 12vw !important;
}

.ant-popover-content {
    border-radius: 10px;
}

.ant-popover-inner {
    border-radius: 10px;
    width: 100%;
}

.ant-popover-inner-content {
    width: 100% !important;
    padding: 0px !important;
}

/* .ant-popover-placement-top .ant-popover-arrow{
    display: none;
  } */

/* .ant-popover-end*/

@media screen and (max-width: 992px) {
    .ant-popover {
        width: 18% !important;
    }

    .thales-app-view .thales-body {
        height: 100vh;
        margin-top: 0vh;
        margin-bottom: 0vh;
        padding: 10px 20px;
    }

}

.menubar {
    width: 50px;
}

@media screen and (max-width: 600px) {
    .thales-app-view .thales-body {
        height: 100vh;
        margin-top: 0vh;
        margin-bottom: 0vh;
        padding: 10px 10px;
    }
}

@media only screen and (max-width: 576px) {


    .thales-app-view .thales-sidebar {
        display: none;
    }

    .menubardiv_hide {
        display: none;
    }

    .thales-sidebar_res {
        position: absolute;
        overflow-y: auto;
        transition: all 0.3s ease;
        z-index: 99999;
        height: 100vh;
        width: 200px;
        background-color: #f2f2f2;
    }

    .thales-sidebar_res .sidebar-main {
        padding-top: 4%;
    }

    .thales-sidebar_res .sidebar-main .txt-center {
        display: none;
    }

    .menubar {
        width: 50px;
    }

    .menubardiv {
        display: flex;
        align-items: center;
        position: relative;
        background-color: transparent;
        z-index: 99999;
        margin: 0 0 0 2%;
    }

    .dashboard-slide-title {
        margin-left: 5%;
    }

    .txt-center {
        margin: auto !important;
    }

    .menu_background {
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        position: absolute;
        z-index: 9999;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {


    .thales-app-view .thales-sidebar {
        display: none;
    }

    .menubardiv_hide {
        display: none;
    }

    .thales-sidebar_res {
        position: absolute;
        overflow-y: auto;
        transition: all 0.3s ease;
        z-index: 99999;
        height: 100vh;
        width: 200px;
        background-color: #f2f2f2;
    }

    .thales-sidebar_res .sidebar-main {
        padding-top: 4%;
    }

    .thales-sidebar_res .sidebar-main .txt-center {
        display: none;
    }

    .menubar {
        width: 50px;
    }

    .menubardiv {
        display: flex;
        align-items: center;
        position: relative;
        background-color: transparent;
        z-index: 99999;
        margin: 0 0 0 2%;
    }

    .dashboard-slide-title {
        margin-left: 5%;
    }

    .txt-center {
        margin: auto !important;
    }

    .menu_background {
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        position: absolute;
        z-index: 9999;
    }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {

    /* .sidebar-main{
    display: none;
} */
    .thales-app-view .thales-sidebar {
        display: none;
    }

    .menubardiv_hide {
        display: none;
    }

    .thales-sidebar_res {
        position: absolute;
        overflow-y: auto;
        transition: all 0.3s ease;
        z-index: 99999;
        height: 100vh;
        width: 200px;
        background-color: #f2f2f2;
    }

    .thales-sidebar_res .sidebar-main {
        padding-top: 4%;
    }

    .thales-sidebar_res .sidebar-main .txt-center {
        display: none;
    }

    .menubar {
        width: 50px;
    }

    .menubardiv {
        display: flex;
        align-items: center;
        position: relative;
        background-color: transparent;
        z-index: 99999;
        margin: 0 0 0 2%;
    }

    .dashboard-slide-title {
        margin-left: 5%;
    }

    .txt-center {
        margin: auto !important;
    }

    .menu_background {
        width: 100vw;
        height: 100vh;
        background-color: transparent;
        position: absolute;
        z-index: 9999;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .menubardiv {
        display: none;
    }

    .thales-sidebar .sidebar-main .reponse_close {
        display: none;
    }

    .menu_background {
        display: none;
    }

    .thales-sidebar_res {
        display: none;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
    .menubardiv {
        display: none;
    }

    .thales-sidebar .sidebar-main .reponse_close {
        display: none;
    }

    .menu_background {
        display: none;
    }

    .thales-sidebar_res {
        display: none;
    }
    .sidebar-main .sidebar-links a {
        padding-left: 1px;
        margin-right: 8px;
    }
}

@media only screen and (min-width: 1401px) {
    .menubardiv {
        display: none;
    }

    .thales-sidebar .sidebar-main .reponse_close {
        display: none;
    }

    .menu_background {
        display: none;
    }

    .thales-sidebar_res {
        display: none;
    }
}